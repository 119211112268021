/**
 * Project filtering
 * @author Dennis Bruinen
 */
var project_filter = function(){
    var self                        = this;
    var locale;
    var class_discipline            = '.js-filter-discipline';
    var class_type                  = '.js-filter-type';
    var class_category              = '.js-filter-category';
    var class_status                = '.js-filter-status';
    var $project_overview_default   = $('.js-project-overview-default');
    var $load_more_projects         = $('.js-load-more-projects');
    var $filter_head                = $('.js-filter-head');
    var $custom_button              = $('.js-custom-button');
    var $reset_filter_parent        = $('.js-filter-parent');
    var $reset_filter               = $('.js-filter-reset');
    var cnt_total_projects          = 0;
    var map;
    var map_initialized             = false;
    var locations                   = [];
    var markers                     = [];
    var view_type                   = $('[data-view-type=default]').attr('data-view-type');

    self.init = function( lc, session_filter ){
        // Set language for ajax calls
        if( lc === 'nl' ){
            locale = '';
        }else{
            locale = '/' + lc;
        }

        // Set the view type if filled
        $.ajax({
            type: 'POST',
            url: locale + '/api/get-active-view-type',
        }).done( function( json ){
            if( json.status === 'OK' ){
                self.showViewType( $('[data-view-type=' + json.view_type + ']') );
            }else{
                self.showViewType( $('[data-view-type=default]') );
            }
        });

        // Prepare the filter with the given session or the existing querystring parameters
        self.prepareFilter( session_filter );

        // Set the listeners on the filter anchors
        $(class_discipline + ', ' + class_type + ', ' + class_category + ', ' + class_status).click(function(e){
            // Show reset filter when a filter has been clicked
            $reset_filter_parent.removeClass('hide');
            // Ignore the offical click
            e.preventDefault();
            // Add or remove the is-active class
            $(this).toggleClass('is-active');
            // Update filter count for this filter column
            self.updateFilterCount( $(this) );
            // Update the results
            self.updateResults();
        });

        // Set the listener of the load more button
        $load_more_projects.click(function(e){
            // Ignore the offical click
            e.preventDefault();
            // Get more results
            self.getMoreResults();
        });

        // Set the listener for small screens, toggling filter options
        $filter_head.click(function(e){
            // Filter toggling is only visible on smaller screens
            if( $(window).width() < getBreakpointSize('large') ){
                // Calculate the wanted height in rem
                var rem = $(this).parent('ul').height() / 10;
                if( rem === 4 ){
                    // Slide filter down
                    var anchor = $(this).parent('ul').find('[data-filter-column]');
                    var height = anchor.parent('li').outerHeight();
                    var cnt_li = anchor.length;
                    // + 4 rem for head and + 1 for a little marge space
                    var new_rem = ( height * cnt_li / 10 ) + 4 + 1;
                    $(this).parent('ul').css('height', new_rem + 'rem');
                    $(this).addClass('is-down');
                }else{
                    // Slide filter up
                    $(this).parent('ul').removeAttr('style');
                    $(this).removeClass('is-down');
                }
            }
        });

        // Reset the filter options height when resizing or changing the orientation
        $(window).on('resize orientationchange', function(){
            $filter_head.parent('ul').removeAttr('style');
        });

        // Set listener for switching between different views
        $('.js-view-type-btn').click(function(e){
            // Ignore the offical click
            e.preventDefault();
            // Show the correct view type
            self.showViewType( $(this) );
        });

        // Set listener for resetting the filter
        $reset_filter.click(function(e){
            e.preventDefault();
            $reset_filter_parent.addClass('hide');
            // Remove is-active classes so the filter data will be emptied
            $( class_discipline ).removeClass('is-active');
            $( class_type ).removeClass('is-active');
            $( class_category ).removeClass('is-active');
            $( class_status ).removeClass('is-active');
            // Update the results
            self.updateResults();
        });
    };

    /**
     * Method for showing the given project overview type
     *
     * @author Dennis Bruinen <dennis@click.nl>
     * @param  String view_type
     */
    self.showViewType = function( $anchor ){
        view_type = $anchor.attr('data-view-type');

        // Remove active classes from all anchors
        $('[data-view-type]').removeClass('is-active');
        // Add active class to anchor
        $anchor.addClass('is-active');

        // Hide all overviews
        $('[class*="js-project-overview"]').addClass('hide');
        // Show the correct one
        $('.js-project-overview-' + view_type).removeClass('hide');

        // Exception for default view
        if( view_type === 'default' ){
            // Show the load more button, if there are more items to load
            var cnt_projects = $('.js-project-column').length;
            if( cnt_projects != cnt_total_projects ){
                $load_more_projects.removeClass('hide');
                // Hide the custom button
                $custom_button.addClass('hide');
            }
        }else{
            // Hide the load more button
            $load_more_projects.addClass('hide');
            // Show the custom button
            $custom_button.removeClass('hide');
        }

        // Exception for map view
        if( view_type === 'map' ){
            self.initProjectMap();
        }

        // Set session for active view_type
        $.ajax({
            type: 'POST',
            url: locale + '/api/set-active-view-type',
            data: {
                view_type: view_type
            },
        });
    }

    /**
     * Method for getting a parameter from the querystrings by name
     *
     * @author Dennis Bruinen
     * @param  String name
     * @return String
     */
    self.getParameterByName = function( name ){
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    };

    /**
     * Prepare the filter after loading, using the session
     *
     * @author Dennis Bruinen <dennis@click.nl>
     */
    self.prepareFilter = function( session_filter ){
        // Build the filter_data object
        var filter_data = {};

        // Get the querystrings
        var d = self.getParameterByName('d');
        var t = self.getParameterByName('t');
        var c = self.getParameterByName('c');
        var s = self.getParameterByName('s');
        var reset = self.getParameterByName('reset');

        var querystrings_on_load = false;
        if( d !== '' && d !== null || t !== '' && t !== null || c !== '' && c !== null || s !== '' && s !== null || reset === 'true' ){
            // There is a querystring, set var so no session filter values but only querystirng parameters will be used
            // This is also used for resetting the filter
            querystrings_on_load = true;
            $reset_filter_parent.removeClass('hide');

        } else {
            $reset_filter_parent.addClass('hide');
        }

        // Get the disciplines
        var arr_disciplines = [];
        if( d !== '' && d !== null ){
            arr_disciplines = d.split(',');
        }else if( session_filter.disciplines !== undefined && !querystrings_on_load ){
            arr_disciplines = session_filter.disciplines.split(',');
        }
        // If any were selected, activate them in the filter
        if( arr_disciplines.length > 0 ){
            // Add is-active class to supposed to be active items
            for( var i = 0; i < arr_disciplines.length; i++ ){
                var $filter_item = $(class_discipline + '[data-filter-id="'+arr_disciplines[i]+'"]');
                $filter_item.addClass('is-active');
            }
            // Update the count
            self.updateFilterCount( $filter_item );
        }

        // Get the types
        var arr_types = [];
        if( t !== '' && t !== null ){
            arr_types = t.split(',');
        }else if( session_filter.types !== undefined && !querystrings_on_load ){
            arr_types = session_filter.types.split(',');
        }
        // If any were selected, activate them in the filter
        if( arr_types.length > 0 ){
            // Add is-active class to supposed to be active items
            for( var i = 0; i < arr_types.length; i++ ){
                var $filter_item = $(class_type + '[data-filter-id="'+arr_types[i]+'"]');
                $filter_item.addClass('is-active');
            }
            // Update the count
            self.updateFilterCount( $filter_item );
        }

        // Get the categories
        var arr_categories = [];
        if( c !== '' && c !== null ){
            arr_categories = c.split(',');
        }else if( session_filter.categories !== undefined && !querystrings_on_load ){
            arr_categories = session_filter.categories.split(',');
        }
        // If any were selected, activate them in the filter
        if( arr_categories.length > 0 ){
            // Add is-active class to supposed to be active items
            for( var i = 0; i < arr_categories.length; i++ ){
                var $filter_item = $(class_category + '[data-filter-id="'+arr_categories[i]+'"]');
                $filter_item.addClass('is-active');
            }
            // Update the count
            self.updateFilterCount( $filter_item );
        }

        // Get the statuses
        var arr_statuses = [];
        if( s !== '' && s !== null ){
            arr_statuses = s.split(',');
        }else if( session_filter.statuses !== undefined && !querystrings_on_load ){
            arr_statuses = session_filter.statuses.split(',');
        }
        // If any were selected, activate them in the filter
        if( arr_statuses.length > 0 ){
            // Add is-active class to supposed to be active items
            for( var i = 0; i < arr_statuses.length; i++ ){
                var $filter_item = $(class_status + '[data-filter-id="'+arr_statuses[i]+'"]');
                $filter_item.addClass('is-active');
            }
            // Update the count
            self.updateFilterCount( $filter_item );
        }

        self.updateResults();
    };

    /**
     * Update the results after filtering
     *
     * @author Dennis Bruinen <dennis@click.nl>
     */
    self.updateResults = function(){
        // Build the filter data object
        var filter_data = self.buildFilterData();
        // We're getting new results, set offset to 0
        filter_data.offset = 0;
        // Update the url
        self.updateUrl( filter_data );
        // Get the projects and insert them into the html
        self.getAndInsertProjects( filter_data, false );
    };

    /**
     * Add results after loading more
     *
     * @author Dennis Bruinen <dennis@click.nl>
     */
    self.getMoreResults = function(){
        // Build the filter data object
        var filter_data = self.buildFilterData();
        // Count the number of projects we already have
        var cnt_projects = $('.js-project-column').length;
        filter_data.offset = cnt_projects;
        // Get the projects and insert them into the html
        self.getAndInsertProjects( filter_data, true );
    };

    /**
     * Build the filter data of the current state
     *
     * @author Dennis Bruinen <dennis@click.nl>
     * @return Object
     */
    self.buildFilterData = function(){
        // Build the filter_data object
        var filter_data = {};

        // Disciplines
        var arr_disciplines = [];
        // Get all active disciplines and add them to filter_data
        $( class_discipline + '[class~="is-active"]').each(function(){
            arr_disciplines.push( $(this).attr('data-filter-id') );
        });
        // Add to filter data
        filter_data.disciplines = arr_disciplines.join(',');

        // Types
        var arr_types = [];
        // Get all active types and add them to filter_data
        $( class_type + '[class~="is-active"]').each(function(){
            arr_types.push( $(this).attr('data-filter-id') );
        });
        // Add to filter data
        filter_data.types = arr_types.join(',');

        // Categories
        var arr_categories = [];
        // Get all active categories and add them to filter_data
        $( class_category + '[class~="is-active"]').each(function(){
            arr_categories.push( $(this).attr('data-filter-id') );
        });
        // Add to filter data
        filter_data.categories = arr_categories.join(',');

        // Statuses
        var arr_statuses = [];
        // Get all active statuses and add them to filter_data
        $( class_status + '[class~="is-active"]').each(function(){
            arr_statuses.push( $(this).attr('data-filter-id') );
        });
        // Add to filter data
        filter_data.statuses = arr_statuses.join(',');

        return filter_data;
    };

    /**
     * Update the url with all the necessary querystring parameters
     *
     * @author Dennis Bruinen <dennis@click.nl>
     * @param  Object filter_data
     */
    self.updateUrl = function( filter_data ){
        // Create the new url
        var new_url = ''
        + '?d=' + (filter_data.disciplines !== null ? filter_data.disciplines : '')
        + '&t=' + (filter_data.types !== null ? filter_data.types : '')
        + '&c=' + (filter_data.categories !== null ? filter_data.categories : '')
        + '&s=' + (filter_data.statuses !== null ? filter_data.statuses : '');

        // Replace the current url
        window.history.replaceState(null, null, new_url);
    };

    /**
     * Get the projects and append or replace them
     *
     * @author Dennis Bruinen <dennis@click.nl>
     * @param  Object filter_data
     * @param  Boolean append
     */
    self.getAndInsertProjects = function( filter_data, append ){
        // If html will not be appended, show loader
        if( !append ){
            $project_overview_default.html('<div class="c-loader js-loader"></div>');
        }

        $.ajax({
            type: 'POST',
            url: locale + '/api/filter-projects',
            data: filter_data,
            complete: function(){
                columnFadeIn('js-animate');
            }
        }).done(function( json ){
            if( json.status === 'OK' ){
                // Add or replace the html
                if( append ){
                    // Append html
                    $project_overview_default.append( json.html );
                }else{
                    // Insert new html in default overview
                    $project_overview_default.html( json.html );
                    // Insert rows in table overview
                    // var datatable is defined in project-table.blade.php
                    datatable.clear();
                    datatable.rows.add( json.table_arr ).draw();

                    // Put locations in global var
                    locations = json.map_arr;
                    if( map_initialized ){
                        // Insert markers in map overview if map is initialized
                        self.updateProjectMap();
                    }
                }

                // Trigger interchange
                $project_overview_default.find('img').each(function(){
                    new Foundation.Interchange( $(this) );
                });
                
                // Handling load more button. Count the number of current projects
                var cnt_projects = $('.js-project-column').length;
                // Set global var fot cnt total projects
                cnt_total_projects = json.cnt_total_projects;

                // Hide load more button when all projects have been loaded into the page
                // or when the view is set to map or list
                if( cnt_total_projects === cnt_projects || view_type !== 'default' ){
                    // Hide the load more button
                    $load_more_projects.addClass('hide');
                    // Show the custom button
                    $custom_button.removeClass('hide');
                }else{
                    // Show the load more button
                    $load_more_projects.removeClass('hide');
                    // Hide the custom button
                    $custom_button.addClass('hide');
                }
            }else if( json.status === 'NO_PROJECTS_FOUND' ){
                $project_overview_default.html('<div class="u-margin-vertical u-font-size-small">' + Lang.get('frontend.js.project.no_results') + '</div>');
                // Hide the load more button
                $load_more_projects.addClass('hide');
                // Show the custom button
                $custom_button.removeClass('hide');
            }
        });
    };

    /**
     * Update the number of active filters
     *
     * @author Dennis Bruinen <dennis@click.nl>
     * @param  jQuery $el
     */
    self.updateFilterCount = function( $el ){
        // Get the current column
        var column = $el.attr('data-filter-column');
        // Get the number of active filter values in this column
        var cnt_active_filter_values = $('.js-filter-'+column+'[class~="is-active"]').length;
        // Insert the current number of active filter values in this column
        var txt_cnt = '';
        if( cnt_active_filter_values > 0 ){
            txt_cnt = '('+cnt_active_filter_values+')';
        }
        $('.js-filter-'+column+'-cnt').text( txt_cnt );
    };

    /**
     * Initialize the project map for marker insertions
     *
     * @author Dennis Bruinen <dennis@click.nl>
     */
    self.initProjectMap = function(){
        // We need to do this only once
        if( !map_initialized ){
            var map_styles = {
                maxZoom: 17,
                minZoom: 3,
                styles: [
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        { "color": "#000000" }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "stylers": [
                        { "visibility": "off" }
                    ]
                },
                {
                    "featureType": "administrative.neighborhood",
                    "stylers": [
                        { "visibility": "off" }
                    ]
                },
                {
                    "featureType": "landscape",
                    "stylers": [
                        { "color": "#b4c3ce" }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        { "color": "#000000" }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        { "color": "#ffffff" }
                    ]
                },
                {
                    "featureType": "landscape.man_made",
                    "stylers": [
                        { "color": "#1d334e" }
                    ]
                },
                {
                    "featureType": "landscape.man_made",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        { "visibility": "off" }
                    ]
                },
                {
                    "featureType": "landscape.natural",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        { "visibility": "off" }
                    ]
                },
                {
                    "featureType": "poi",
                    "stylers": [
                        { "color": "#b4c3ce" }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text",
                    "stylers": [
                        { "visibility": "off" }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        { "color": "#ffffff" }
                    ]
                },
                {
                    "featureType": "poi.business",
                    "stylers": [
                        { "visibility": "off" }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels",
                    "stylers": [
                        { "visibility": "off" }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.icon",
                    "stylers": [
                        { "visibility": "off" }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        { "color": "#000000" }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        { "color": "#ffffff" }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        { "color": "#ffffff" },
                        { "saturation": -100 }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        { "color": "#ffffff" }
                    ]
                },
                {
                    "featureType": "transit",
                    "stylers": [
                        { "visibility": "off" }
                    ]
                },
                {
                    "featureType": "water",
                    "stylers": [
                        { "color": "#e2ecf1" }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text",
                    "stylers": [
                        { "visibility": "off" }
                    ]
                }
                ]
            };
            map = new google.maps.Map(document.getElementById('project-map'), map_styles);
            map_initialized = true;
            // Insert locations
            self.updateProjectMap();
        }
    };

    /**
     * Update the project map with markers
     *
     * @author Dennis Bruinen <dennis@click.nl>
     */
    self.updateProjectMap = function(){
        // Map is initialized, let's do this
        if( map_initialized ){
            if( locations.length > 0 ){
                // Make new bounds var
                var bounds = new google.maps.LatLngBounds();

                // Reset markers
                self.removeAllMarkers();
                markers = [];

                for( var i = 0; i < locations.length; i++ ){
                    // Make lat_lng object
                    var lat_lng = { lat: locations[i].lat, lng: locations[i].lng };
                    // Create marker
                    var marker = new google.maps.Marker({
                        position: lat_lng,
                        map: map,
                        // unofficial attribute, but we need it for clicking
                        url: locations[i].url
                    });
                    // Add to global array
                    markers.push( marker );
                    // Add to bounds for centering
                    bounds.extend( lat_lng );
                    // Add click listener for marker
                    google.maps.event.addListener(marker, 'click', function() {
                        window.location.href = this.url;
                    });
                }
                // Cluster styles
                var cluster_styles = {
                    imagePath: '/assets/images/markers/m', 
                    styles : [
                        {
                            textColor: 'white',
                            url: '/assets/images/markers/m1.png',
                            height: 52,
                            width: 53
                        },
                        {
                            textColor: 'white',
                            url: '/assets/images/markers/m2.png',
                            height: 55,
                            width: 56
                        },
                        {
                            textColor: 'white',
                            url: '/assets/images/markers/m3.png',
                            height: 65,
                            width: 66
                        }
                    ]
                };
                // Cluster the markers
                var marker_cluster = new MarkerClusterer(map, markers, cluster_styles);
                // Center the map
                map.fitBounds(bounds);
            }
        }
    };

    /**
     * Remove all markers from map
     *
     * @author Dennis Bruinen <dennis@click.nl>
     */
    self.removeAllMarkers = function(){
        // Iterate through markers and remove them from the map
        for (var i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
        }
    }
};