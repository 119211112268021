/**
 * Kind of lazy load for images
 * Fade in the images when they appear in the viewport
 * 
 * @author Rianne Osthoek
 */
var lazy_load_image = function() {
    var self = this;
    var $images = $('.js-lazy-load');

    self.init = function() {
        // Execute method when page has been fully loaded
        $(window).on('load', function(){
            self.onScrollDown();
        });
    };

    self.onScrollDown = function() {
        // Remove class from image when it appears in the viewport window
        // when scrolling down
        $images.waypoint(function(direction) {
            var $el = $(this.element);
            if (direction === 'down') {
                $el.removeClass('is-invisible');
            }
        }, {
            // offset: '70%'
            offset: function() {
                // Same calculation as for changing bg color
                return + $(window).height() / 100 * 75;
            }
        });
    };
};