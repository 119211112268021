/*
 * CUSTOM JAVASCRIPT
 *
 * All of your custom javascript can be placed below.
 * Do NOT forgot to add comments above the different functions or functionalities that need explaining.
 *
 */

var $window = $(window);
var $body = $('body');
/**
 * Get token from meta tag in header and add CSRF token to the ajax request
 * 
 * @author Rick van der Burg <rick@click.nl>
 */
function prepareForAjax() {
    $.ajaxSetup(
        {
            headers:
            {
                'X-CSRF-Token': $('meta[name="_token"]').prop('content')
            }
        });
}

prepareForAjax();

/**
 * Get value of given breakpoint based on Sass breakpoints
 *
 * @author Rianne Oosthoek
 * @param  {string} name of the breakpoint (small, medium, etc.)
 * @return {int}    breakpoint size in pixels
 */
function getBreakpointSize( size ){
    // Loop through Foundation breakpoints
    for( var i in Foundation.MediaQuery.queries ) {
        var query = Foundation.MediaQuery.queries[i];
        if( size === query.name ){
            // Split media query string so only the em value remains
            var str = query.value;
            var split_str = str.split('min-width: ')[1];
            // Split string and parse em value to and get px value
            var query_value = ( parseFloat( split_str.split('em)')[0] ) * 16 ) ;

            return query_value;
        }
    }
}

/**
 * Method for fade in animation of search/filter results
 *
 * @author Rianne Oosthoek
 * @param  {string} element class of the selected element
 */
function columnFadeIn( element ){
    var $elements = $('.is-invisible.' + element);
    var time_out = 0;

    // Set timeout so we have some delay to execute the animation
    setTimeout(function(){
        $elements.each(function() {
            var $el = $(this);
            time_out = time_out + 100;
            // Set another timeout so the columns appear one by one
            setTimeout( function(){
                $el.removeClass('is-invisible'); 
            }, time_out);
        });
    }, 250);
}

/**
 * Smooth fade animation when switching between pages
 *
 * @author Rianne Oosthoek
 */
function pageFadeInAndOut() {
    // Fade in
    $window.on('load', function(){
        $body.removeClass('is-invisible');
    });

    // Fade out
    $body.on('click', 'a:not([class*="js-"], [target="_blank"], [href*="mailto:"], [href*="tel:"], [href*="#"], [class*="sf-dump-ref"])', function(e){
        e.preventDefault();
        var href = $(this).attr('href');
        // Fade out the page
        $body.addClass('is-invisible').css('background-color','#d7dde0');
        // Wait 500ms before redirecting to the page
        setTimeout(function(){
            window.location.href = href;
        }, 300);
    });
}

pageFadeInAndOut();

/**
 * Animated scroll functionality
 *
 * @author Rianne Oosthoek
 */
var scroll_to = function() {
    var self = this;
    var menu_height = $('.js-header').innerHeight() + 15;

    self.init = function() {
        self.animatedScroll();        
    };

    /**
     * Animate to element after clicking on an anchor
     *
     * @author Rianne Oosthoek
     */
    self.animatedScroll = function() {
        $('.js-scroll-to').click(function(e){
            var href = $(this).attr('href');
            var scroll_id = href.split('#')[1];

            // Check if element exists on the page
            if( $('#' + scroll_id).length ){
                $('html, body').animate({
                    scrollTop: self.calculateScroll( $('#' + scroll_id) )
                }, 1000);
            }
        });
    };

    /**
     * Jump to section on a new page after clicking an anchor
     *
     * @author Rianne Oosthoek
     */
    self.jumpToSection = function() {
        var hash = window.location.hash;
        
        if( hash !== '' ){
            $window.on('load', function(){
                $('html, body').scrollTop( self.calculateScroll( $(hash) ) );
            });
        }
    };

    /**
     * Calculate scrolltop minus height of menu
     *
     * @author Rianne Oosthoek
     * @return {[type]} [description]
     */
    self.calculateScroll = function( $element ) {
        var offset_top = $element.offset().top;
        return offset_top - menu_height;
    };
};

/**
 * Animates menu
 *
 * @author Rianne Oosthoek & Mannus van der Meer
 * @credits
 */
var sticky_menu = function(){
    var self = this;
    var $header = $('.js-header');
    var $payoff = $('.js-header .js-logo-payoff');

    self.init = function(){
        // Previous scroll position
        var prev_scroll = 0;
        // Needed to add and remove logo from fixed menu just once
        var is_fixed_menu = false;
        var removed_payoff = false;

        $window.scroll(function(){
            // Determine the scroll position
            var current_scroll = $window.scrollTop();

            // When you haven't scrolled past the main nav
            if ( current_scroll < $header.outerHeight() ){
                $header.removeClass('is-up is-down is-fixed');
                // Reset previous scroll position
                prev_scroll = current_scroll;
                is_fixed_menu = false;
            // When scrolled past the header and the scroll direction is down, hide menu
            }else if( prev_scroll < current_scroll ){
                $header.addClass('is-up is-fixed').removeClass('is-down');
                // Reset previous scroll position
                prev_scroll = current_scroll;
                is_fixed_menu = true;
            // When scrolled past the header and the scroll direction is up, show menu
            }else if( prev_scroll > current_scroll ){
                $header.addClass('is-down is-fixed').removeClass('is-up');
                // Reset previous scroll position
                prev_scroll = current_scroll;
                is_fixed_menu = true;

            }

            // Show logo or home text in menu (execute just once)
            if( is_fixed_menu && !removed_payoff ){
                removed_payoff = true;
            }else if( !is_fixed_menu && removed_payoff ){
                removed_payoff = false
            }

        });
    };
};