/**
 * Employee filtering
 * @author Dennis Bruinen
 */
var employee_filter = function(){
    var self                    = this;
    var locale;
    var $employee_container     = $('.js-employee-container');
    var loader_html             = '<div class="c-loader js-loader"></div>';

    self.init = function( lc ){
        // Set language for ajax calls
        if( lc === 'nl' ){
            locale = '';
        }else{
            locale = '/' + lc;
        }

        // Fade in employees on page load
        columnFadeIn('js-animate');

        var timeout_id;

        // Set the listeners on the filter anchors
        $('.js-employee-search').keyup(function(){
            // Get entered value
            var input_val = $(this).val()
            // Show loader, if not already shown
            if( $employee_container.html() !== loader_html ){
                $employee_container.html( loader_html );
            }
            // Give the users some time to enter a value before it starts searching
            clearTimeout( timeout_id );

            timeout_id = setTimeout(function(){
                // Update the results
                self.updateResults( {q: input_val} );
            }, 500);
        });

        // Prevent users from submitting the form
        $('.js-form-employee-search').submit(function(e){
            e.preventDefault();
        });
    };

    /**
     * Get the employees and replace them
     *
     * @author Dennis Bruinen <dennis@click.nl>
     * @param  Object filter_data
     */
    self.updateResults = function( filter_data ){
        $.ajax({
            type: 'POST',
            url: locale + '/api/filter-employees',
            data: filter_data,
            complete: function(){
                columnFadeIn('js-animate');
            }
        }).done(function( json ){
            if( json.status === 'OK' ){
                // Add or replace the html
                $employee_container.html( json.html );
                // Trigger interchange
                $employee_container.find('img').each(function(){
                    new Foundation.Interchange( $(this) );
                });
            }else if( json.status === 'NO_EMPLOYEES_FOUND' ){
                $employee_container.html('<div class="text-center u-font-size-small small-12">' + Lang.get('frontend.js.team.no_results') + '</div>');
            }
        });
    };
};