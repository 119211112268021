/**
 * Global searching
 * @author Dennis Bruinen
 */
var global_search = function(){
    var self                    = this;
    var locale;
    var $body                   = $('body');
    var loader_html             = '<div class="c-loader js-loader"></div>';
    var $results_overview;

    self.init = function( lc ){
        // Set language for ajax calls
        if( lc === 'nl' ){
            locale = '';
        }else{
            locale = '/' + lc;
        }

        var timeout_id;

        // Set the listeners on the filter anchors
        $body.on('keyup', '.js-global-search', function(){
            // Get the overview div here, search overlay is loaded asynchronously
            $results_overview = $('.js-results-overview');

            if( $(this).val().length > 1 ){
                // Get entered value
                var input_val = $.trim( $(this).val() );
                // Show loader, if not already shown
                if( $results_overview.html() !== loader_html ){
                    $results_overview.html( loader_html );
                }
                // Give the users some time to enter a value before it starts searching
                clearTimeout( timeout_id );

                timeout_id = setTimeout(function(){
                    // Update the results
                    self.updateResults( {q: input_val} );
                }, 500);
            }else{
                $results_overview.html('<div class="text-center u-font-size-small small-12">' + Lang.get('frontend.js.search.empty') + '</div>');
            }
        });

        // Prevent users from submitting the form
        $body.on('submit', '.js-form-global-search', function(e){
            e.preventDefault();
        });
    };

    /**
     * Get the search results and replace them
     *
     * @author Dennis Bruinen <dennis@click.nl>
     * @param  Object filter_data
     */
    self.updateResults = function( filter_data ){
        $.ajax({
            type: 'POST',
            url: locale + '/api/global-search',
            data: filter_data,
            complete: function(){
                columnFadeIn('js-search-item');
            }
        }).done(function( json ){
            if( json.status === 'OK' ){
                 // Add or replace the html
                $results_overview.html( json.html );
                // Trigger interchange
                $results_overview.find('img').each(function(){
                    new Foundation.Interchange( $(this) );
                });
            }else if( json.status === 'NO_RESULTS_FOUND' ){
                $results_overview.html('<div class="text-center u-font-size-small small-12">' + Lang.get('frontend.js.search.no_results') + '</div>');
            }
        });
    };
};