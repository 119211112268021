/**
 * All overlay functionalities
 */

/**
 * Functionality for the overlay
 *
 * @author Rianne Oosthoek
 */
var overlay = function() {
    var self = this,
        $body = $('body'),
        $overlay = $('.js-overlay'),
        $overlay_content = $('.js-overlay-content'),
        button_class = '.js-overlay-button';

    self.init = function() {
        $body.on('click', button_class, function(e){
            e.preventDefault();

            var _this = $(this),
                type = _this.attr('data-open');            

            // Overlay is empty, open and fill overlay with correct content
            // or overlay already has content, another overlay will be opened
            if( $overlay.hasClass('hide') || type !== $overlay.attr('data-overlay') ){
                self.openOverlay( type );
            // Close the overlay and remove the content
            }else{
                // Close overlay
                $overlay.addClass('hide').attr('data-overlay','');
                // Remove content
                $overlay_content.html('');
                // Enable body scrolling
                $body.removeClass('o-overlay-open');
            }
        });
    };

    /**
     * Open the overlay and retrieve the correct content
     * via an Ajax call
     *
     * @author Rianne Oosthoek
     * @param  {string} type search, menu
     */
    self.openOverlay = function(type) {
        var $logo = $('.js-overlay .js-logo'),
            url_parameters = self.getUrlParameters(),
            $logo_payoff = $('.js-overlay .js-logo-payoff'); 

        // Change logo src depending on overlay
        if( type === 'menu' ){
            var logo_path = '/assets/images/frontend/logo_dzh_white.svg',
                payoff_path = '/assets/images/frontend/logo_dzh_payoff_white.svg';
        }else{
            var logo_path = '/assets/images/frontend/logo_dzh.svg',
                payoff_path = '/assets/images/frontend/logo_dzh_payoff.svg';
        }

        // Add locale to url 
        var locale = $('html').attr('lang');
        locale = (locale === 'nl' ? '' : locale +'/'); 

        // Ajax call to retrieve the content
        $.ajax({
            type: 'GET',
            url: '/' + locale + 'api/overlay/' + type + '?path=' + url_parameters, 
            dataType: 'json'
        }).done(function( json ){
            if( json.status === 'OK' ){
                $overlay_content.html( json.html );
                // Set src of logo and payoff
                $logo.attr('src', logo_path);
                $logo_payoff.attr('src', payoff_path);
                // Disable body scrolling
                $body.addClass('o-overlay-open');
                // Open overlay
                $overlay.removeClass('hide').attr('data-overlay',type);

                if(type === 'menu') {
                    $('.js-language-links').each(function() {
                        var href = $(this).attr('href');
                        $(this).attr('href', href + json.url);
                    });
                }
            } else {
                // ERROR LOGGING!
                console.log( Lang.get('frontend.js.global.error_retrieving_data') );
            }
        });
    }

    /**
     * Get the url parameters from the url
     *
     * @author Rick van der Burg <rick@click.nl>
     * @return string 
     */ 
    self.getUrlParameters = function() {
        return window.location.pathname;   
    }
};