/**
 * Change the background color of the page when scrolling
 *
 * @author Rick van der Burg
 */
var changing_bg_when_scrolling = function() {
    var self        = this;
    var $body       = $('body');
    var $header     = $('.js-header');
    var $sections   = $('.js-change-color');

    self.init = function() {
        self.onScrollDown();
        self.onScrollUp();
        self.resetBackgroundColor();
        self.resetWaypointOnLastElement();
    };

    /**
     * Reset background color to empty value so it becomes default
     *
     * @author Rick van der Burg <rick@click.nl>
     * @return void 
     */ 
    self.resetBackgroundColor = function() {
console.lo
        $body.css('background-color', '#d7dde0');
        $header.css('background-color', '#d7dde0');
    };

    /**
     * Set Background Color 
     * 
     * @author Rick van der Burg <rick@click.nl>
     * @param HTMLObject element 
     * @return void 
     */ 
    self.setBackground = function(element) {
        var current_color = $(element).attr('data-color');
        $body.css('background-color', current_color);
        $header.css('background-color', current_color);
    };

    self.onScroll = function() {
        if($body.scrollTop() == 0) {
            self.resetBackgroundColor();
        }
    };

    /**
     * Show image 
     * @param  HTMLObject element 
     * @author Rick van der Burg <rick@click.nl>
     * @return void         
     */
    self.showImage = function(element) {
        $(element).addClass('is-visible'); 
    };

    /**
     * When scrolling down
     * 
     * @author Rick van der Burg <rick@click.nl>
     * @return void 
     */ 
    self.onScrollDown = function() {
        $sections.waypoint(function(direction) {
            if (direction === 'down') {
                self.setBackground(this.element);
                self.showImage(this.element);                
            }
        }, {
            offset: function() {
                return + $(window).height() / 100 * 75;
            }
        });
    };

    /**
     * Reset way point (and background color) when last HTML element has been reached
     *
     * @author Rick van der Burg <rick@click.nl>
     * @return void 
     */ 
    self.resetWaypointOnLastElement = function() {
        $sections.last().next().first().waypoint(function(direction) {
            self.resetBackgroundColor();
        }, {
            offset: '50%'
        });
    };

    /**
     * When scrolling up let the color be the same
     *
     * @author Rick van der Burg <rick@click.nl>
     * @return void
     */ 
    self.onScrollUp = function() {
        $sections.waypoint(function(direction) {
            if (direction === 'up') {
                self.setBackground(this.element);
                self.showImage(this.element);   
            }
        }, {
            offset: function() {
                return - $(window).height() / 100 * 75;
            }
        });
    };
};