/**
 * News
 * @author Dennis Bruinen
 */
var news = function(){
    var self                    = this;
    var locale;
    var $news_overview          = $('.js-news-overview');
    var $load_more_news_items   = $('.js-load-more-news-items');

    self.init = function( lc ){
        // Set language for ajax calls
        if( lc === 'nl' ) {
            locale = '';
        } else if ( lc === 'en') {
            locale = '';
        } else if ( lc === 'de') {
            locale = '';
        } else {
            locale = '/' + lc;
        }

        // Fade in news on page load
        columnFadeIn('js-animate');

        // Set the listener of the load more button
        $load_more_news_items.click(function(e){
            // Ignore the offical click
            e.preventDefault();
            // Get more results
            self.getMoreResults();
        });
    };

    /**
     * Add results after loading more
     *
     * @author Dennis Bruinen <dennis@click.nl>
     */
    self.getMoreResults = function(){
        // Count the number of news_items we already have
        var filter_data = {};
        var cnt_news_items = $('.js-news-item-column').length;
        filter_data.offset = cnt_news_items;

        // Get the news items and insert them into the html
        $.ajax({
            type: 'POST',
            url: locale + '/api/more-news-items',
            data: filter_data,
            complete: function(){
                columnFadeIn('js-animate');
            }
        }).done(function( json ){
            if( json.status === 'OK' ){
                // Add or replace the html
                $news_overview.append( json.html );

                // Trigger interchange
                $news_overview.find('img').each(function(){
                    new Foundation.Interchange( $(this) );
                });

                // Count the number of current news items
                var cnt_news_items = $('.js-news-item-column').length;
                if( json.cnt_total_news_items === cnt_news_items ){
                    // Hide the load more button
                    $load_more_news_items.addClass('hide');
                }else{
                    // Show the load more button
                    $load_more_news_items.removeClass('hide');
                }
            }
        });
    }
};