/**
 * Slick Carousel
 * @credits http://kenwheeler.github.io/slick/
 * @author Rianne Oosthoek
 */
var slider = function() {
    var self = this;

    self.init = function() {
        $('.js-slider').each(function(){
            var $this = $(this);

            // Only initialize Slick only when there are more than 1 slides
            if( $this.find('.c-slide').length > 1 && !$this.hasClass('slick-initialized') ){
                $this.slick({
                    centerMode:     false,
                    cssEase:        'ease-in-out',
                    nextArrow:      '<button type="button" class="slick-next"><span class="icon-arrow-68"></span></button>',
                    prevArrow:      '<button type="button" class="slick-prev"><span class="icon-arrow-67"></span></button>',
                    slidesToShow:   4,
                    responsive: [
                        {
                            breakpoint: getBreakpointSize('medium'),
                            settings: {
                                arrows:         false,
                                centerMode:     true,
                                slidesToShow:   1
                            },
                        },{
                            breakpoint: getBreakpointSize('large'),
                            settings: {
                                centerMode:     false,
                                slidesToShow:   3
                            },
                        },{
                            breakpoint: getBreakpointSize('xlarge'),
                            settings: {
                                centerMode:     false,
                                slidesToShow:   4
                            },
                        }
                    ]
                });
            }
        });
    };
};